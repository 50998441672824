
import { Component, Inject, Output, EventEmitter } from '@angular/core';
import { ActivatedRoute, Router, RouterModule } from '@angular/router';
import { APP_BASE_HREF, CommonModule } from '@angular/common';
// import { environment } from 'src/environments/environment';
import { AuthService } from '@auth0/auth0-angular';
import { MaterialModule } from '../material/material.module';
import { environment } from '../../../../environments/environment';
import { NavigationService } from '../../data-access/navigation.service';
// import { ChatService } from '../../services/ChatService.service';
@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  imports: [CommonModule, RouterModule, MaterialModule],
  standalone: true,

})
export class NavbarComponent {
  loc_origin = location.origin;
  // base_url = environment.auth0credentials.authorizationParams.redirect_uri;
  // @Output() userSelectionEvent = new EventEmitter<string>();
  mobileNavToggle = false;

  public constructor(
    // @Inject(APP_BASE_HREF) public baseHref: string,
    private router: ActivatedRoute,
    private route: Router,
    public auth: AuthService,
    private navigationService: NavigationService
    // private chatService: ChatService
  ) {
  }

  onUserSelection(selection: string) {
    // this.userSelectionEvent.emit(selection);
  }

  toggleMobileMenu() {
    
    this.mobileNavToggle = this.mobileNavToggle ? false : true;

  }

  logout() {
    // this.chatService.clearMessages();
    this.auth.logout();
  }

}
